
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { StrategyForm } from '@/types/machineryManage'

@Component({
  name: 'Detail'
})

export default class extends Vue {
  @Prop() readonly showDialog!: boolean
  @Prop() readonly strategyId!: string

  private loading = false
  private list = []
  formData: StrategyForm = {
    strategyName: '',
    termList: []
  }

  @Watch('strategyId')
  isDetail (val: string) {
    val && this.getDetail()
  }

  created () {
    // 1
  }

  getDetail () {
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryStrategyByStrategyId, { strategyId: this.strategyId }).then(res => {
      this.formData = res || []
    })
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
