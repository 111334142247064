
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Maintenance, StrategyForm, Strategy } from '@/types/machineryManage'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component({
  name: 'AddMaintenance'
})
export default class extends Vue {
    @Prop() readonly showDialog!: boolean
    @Prop() readonly strategyId!: string

    private loading = false
    private submitShow = false
    private list = []
    formData: StrategyForm = {
      strategyName: '',
      termList: []
    }

    private oldData: { [key: number]: Strategy } = {}
    private isAddType: number | null = null
    private validtor999 = (rule: any, value: string, callback: Function) => {
      if (+value > 99999) {
        return callback(new Error('请输入1-99999的数字'))
      }
      return callback()
    }

    private rules = {
      strategyName: [
        { required: true, message: '请输入策略名称', trigger: ['blur', 'change'] }
      ],
      termList: [
        { required: true, message: '请输入保养项目', trigger: ['blur', 'change'] }
      ],
      termId: [
        { required: true, message: '请选择维保项', trigger: ['blur', 'change'] }
      ],
      strategyDay: [
        { required: true, message: '请输入维保周期', trigger: ['blur', 'change'] },
        { validator: this.validtor999, trigger: ['blur', 'change'] },
        { validator: pInteger, trigger: ['blur', 'change'] }
      ],
      strategyTime: [
        { required: true, message: '请输入维保周期', trigger: ['blur', 'change'] },
        { validator: this.validtor999, trigger: ['blur', 'change'] },
        { validator: pInteger, trigger: ['blur', 'change'] }
      ]
    }

    @Watch('strategyId')
    isDetail (val: string) {
      val && this.getDetail()
    }

    @Watch('showDialog')
    isShowDialog (val: string) {
      val && this.getConfigList()
    }

    created () {
      // 1
    }

    getConfigList () {
      this.loading = true
      this.$axios.get(this.$apis.machineryManage.selectYhMachineryTermByList).then(res => {
        if (res) {
          this.list = res.list || []
        }
      }).finally(() => {
        this.loading = false
      })
    }

    getDetail () {
      this.$axios.get(this.$apis.machineryManage.selectYhMachineryStrategyByStrategyId, { strategyId: this.strategyId }).then(res => {
        this.formData = res || []
      })
    }

    // 每个保养项不重复
    getExtraList (rowIndex: number) {
      const list: any[] = []
      this.formData.termList.forEach((item, index) => {
        if (rowIndex !== index) {
          list.push(item.termId)
        }
      })
      return this.list.filter((item: any) => {
        return list.every((item1) => {
          return item.termId !== item1
        })
      })
    }

    onAdd () {
      if (this.isAddType != null) {
        this.$message.warning('请先保存！')
      } else {
        this.formData.termList.push({
          termId: '',
          termName: '',
          strategyTime: '',
          strategyDay: '',
          isInput: true,
          termTime: '',
          termDay: ''
        })
        this.isAddType = this.formData.termList.length - 1
        this.$nextTick(() => {
          ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
            .table as any).bodyWrapper.scrollHeight
        }) // 滚动到表格最底部
      }
    }

    onEdit (row: Strategy, index: number) {
      if (this.isAddType != null) {
        this.$message.warning('请先保存！')
      } else {
        this.formData.termList[index] = {
          ...row,
          isInput: true
        }
        this.oldData[index] = row
        this.isAddType = index
        this.$set(this.formData.termList, index, this.formData.termList[index]) // 重新渲染表格数据
      }
    }

    // 选择保养项名称判断名下保养周期单位
    selectMaintance (row: Maintenance, index: number) {
      const data: Maintenance[] = this.list.filter((item: Maintenance) => {
        return item.termId === row.termId
      })
      this.formData.termList[index].termName = data[0].termName
      this.formData.termList[index].dayShow = false
      this.formData.termList[index].timeShow = false
      this.formData.termList[index].termTime = ''
      this.formData.termList[index].termDay = ''
      this.formData.termList[index].strategyTime = ''
      this.formData.termList[index].strategyDay = ''
      // 按天/次
      if (data[0].termDay === '1') {
        this.formData.termList[index].dayShow = true
      }
      // 按小时
      if (data[0].termTime === '1') {
        this.formData.termList[index].timeShow = true
      }
      this.$set(this.formData.termList, index, this.formData.termList[index]) // 重新渲染表格数据
    }

    onDelete (index: number) {
      this.formData.termList.splice(index, 1)
    }

    onSave (row: Maintenance, index: number) {
      ;(this.$refs.table as ElForm).validate(valid => {
        if (valid) {
          this.isAddType = null
          this.formData.termList[index].isInput = false
          this.formData.termList[index].strategyId = '1'
          this.$set(this.formData.termList, index, row)
        }
      })
    }

    onCancel (row: Maintenance, index: number) {
      if (row.strategyId) {
        this.formData.termList[index].isInput = false
        this.$set(this.formData.termList, index, this.oldData[index])
      } else {
        this.formData.termList.splice(index, 1)
      }
      this.isAddType = null
    }

    onSubmit () {
      ;(this.$refs.formData as ElForm).validate(valid => {
        if (valid) {
          ;(this.$refs.table as ElForm).validate(valid1 => {
            if (valid1) {
              if (this.formData.termList.length === 0) {
                this.$message.warning('请添加保养项目！')
                return
              }
              if (this.isAddType != null) {
                this.$message.warning('请先保存！')
                return
              }
              this.submitShow = true
              const url = this.strategyId ? this.$apis.machineryManage.updateYhMachineryStrategy : this.$apis.machineryManage.insertYhMachineryStrategy
              const arr = this.formData.termList.map((item: any) => {
                return {
                  strategyTime: item.strategyTime,
                  termId: item.termId,
                  strategyDay: item.strategyDay
                }
              })
              const params = {
                termList: arr,
                strategyName: this.formData.strategyName
              }
              this.$axios.post(url, this.strategyId ? {
                ...params,
                strategyId: this.strategyId
              } : params).then(() => {
                this.$message.success('保存成功')
                this.closeDialog()
              }).finally(() => {
                this.submitShow = false
              })
            }
          })
        }
      })
    }

    closeDialog () {
      this.$emit('update:showDialog', false)
      ;(this.$refs.formData as ElForm).resetFields()
      this.formData.termList = []
      this.$emit('success')
      this.isAddType = null
    }
}
