

import { Vue, Component } from 'vue-property-decorator'
import AddMaintenance from './AddMaintenance.vue'
import AddStrategy from './AddStrategy.vue'
import Detail from './Detail.vue'

@Component({
  components: { AddMaintenance, AddStrategy, Detail }
})
export default class InsectPest extends Vue {
  private showDialog = false
  private showDialog1 = false
  private showDialog2 = false
  private strategyId = ''
  private strategyId1 = ''
  private types = ['启用', '禁用']
  private searchInfo = {
    strategyName: '',
    strategyState: ''
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.loadData()
  }

  activated () {
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryStrategyByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onUse (strategyId: string) {
    this.loading = true
    this.$axios.post(this.$apis.machineryManage.updateYhMachineryStrategyState, { strategyId }).then(() => {
      this.$message.success('设置成功')
      this.loadData()
    }).finally(() => {
      this.loading = false
    })
  }

  onEdit (strategyId: string) {
    this.showDialog1 = true
    this.strategyId = strategyId
  }

  onDetail (strategyId: string) {
    this.showDialog2 = true
    this.strategyId1 = strategyId
  }

  success () {
    this.strategyId = ''
    this.loadData()
  }

  onDelete (strategyId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryStrategy, { strategyId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
