
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Maintenance } from '@/types/machineryManage'

@Component({
  name: 'AddMaintenance'
})
export default class extends Vue {
  @Prop() readonly showDialog!: boolean

  private loading = false
  private tableData: Maintenance[] = []

  private oldData: { [key: number]: Maintenance } = {}
  private isAddType: number | null = null

  @Watch('showDialog')
  isDetail (val: string) {
    val && this.getConfigList()
  }

  created () {
    // 2
  }

  getConfigList () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryTermByList).then(res => {
      if (res) {
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        termName: '',
        termDay: '',
        termTime: '',
        isInput: true
      })
      this.isAddType = this.tableData.length - 1
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: Maintenance, index: number) {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = row
      this.isAddType = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (termId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.machineryManage.deleteDataYhMachineryTerm, { termId: termId }).then(() => {
        this.$message.success('删除成功')
        this.isAddType = null
        this.getConfigList()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onSave (row: Maintenance) {
    if (!row.termName) {
      this.$message({
        message: '请填写保养项名称',
        type: 'warning'
      })
    } else if ((!row.termDay && !row.termTime) || (row.termDay === '2' && row.termTime === '2')) {
      this.$message({
        message: '请至少填写一个保养周期单位',
        type: 'warning'
      })
    } else {
      delete row.isInput
      const url = row.termId ? this.$apis.machineryManage.updateYhMachineryTerm : this.$apis.machineryManage.insertYhMachineryTerm
      this.$axios.post(url, row).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.$emit('success')
        this.isAddType = null
        this.getConfigList()
      })
    }
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
    this.isAddType = null
  }
}
